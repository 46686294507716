<template>
  <div>
    <div v-if="!isAdd && !isDetails && !isContrast">
      <div class="card" style="display: flex">
        <div class="left">
          <el-button type="primary" @click="handleAdd" icon="el-icon-plus"
            >新增调拨统计</el-button
          >
        </div>
        <div class="line"></div>
        <div class="middle">
          <el-form :model="cond" label-width="80px">
            <el-form-item label="统计名称">
              <el-input
                clearable
                v-model="cond.name"
                autocomplete="off"
                placeholder="请输入统计名称"
              ></el-input
            ></el-form-item>
          </el-form>
        </div>
        <div>
          <el-button
            type="primary"
            @click="searchData()"
            style="margin-left: 10px; height: 40px"
            >搜索</el-button
          >
          <el-button @click="handleReset" style="height: 40px">重置</el-button>
        </div>
      </div>
      <div class="table">
        <el-table
          :data="tableData"
          stripe
          height="700"
          @row-dblclick="handleDalclick"
        >
          <el-table-column
            type="index"
            label="序号"
            align="center"
            width="80"
          ></el-table-column>
          <el-table-column
            prop="realname"
            label="创建人"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="统计名称"
            align="center"
          ></el-table-column>
          <el-table-column prop="name" label="调拨类型" align="center">
            <template slot-scope="scope">
              <div>
                {{ scope.row.conditions.allot_type === "10" ? "调出" : "调入" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="签收时间" align="center">
            <template slot-scope="scope">
              <div>
                {{ time_type[scope.row.conditions.time_type] }}
              </div>
            </template></el-table-column
          >
          <!-- <el-table-column
            prop="name"-
            label="款式"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="材质成色"
            align="center"
          ></el-table-column> -->
          <el-table-column prop="action" label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="handleDetails(scope.row)"
                >查看</el-button
              >
              <el-button type="text" @click="handleEdit(scope.row)"
                >编辑</el-button
              >
              <el-button type="text" @click="handleDel(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          :page-sizes="[50, 100, 200, 500]"
          :page-size="cond.page_size"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <Add
      v-if="isAdd"
      @hide="getList"
      :allot_statistical_id="allot_statistical_id"
    />
    <TransferData
      v-if="isDetails"
      @hide="getList"
      :allot_statistical_id="allot_statistical_id"
    />
    <Contrast v-if="isContrast" :allot_statistical_id="allot_statistical_id" />
  </div>
</template>

<script>
import {
  getStatisticalList,
  deleteStatistical,
} from "@/api/statistics/transfer/index.js";
import Add from "./Add.vue";
import TransferData from "./transferData.vue";
import Contrast from "./contrast.vue";
export default {
  data() {
    return {
      cond: {
        page: 1,
        page_size: 50,
      },
      tableData: [],
      rowList: [],
      isAdd: false,
      isDetails: false,
      isContrast: false,
      allot_statistical_id: 0,
      time_type: {
        month: "按月",
        day: "按天",
        year: "按年",
      },
      total: 0,
    };
  },
  components: {
    Add,
    TransferData,
    Contrast,
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.isDetails = false;
      this.isAdd = false;
      const data = {
        ...this.cond,
      };
      getStatisticalList(data).then((res) => {
        if (res.code === 1) {
          this.tableData = res.data.list;
          this.total = res.data.count;
        }
      });
    },
    /*
     *  新增调拨统计
     */
    handleAdd() {
      this.isAdd = true;
      this.allot_statistical_id = 0;
    },
    /*
     *  搜索
     */
    searchData() {
      getStatisticalList(this.cond).then((res) => {
        if (res.code === 1) {
          this.tableData = res.data.list;
          this.total = res.data.cound;
        }
      });
    },
    /*
     *  重置
     */
    handleReset() {
      this.cond.name = "";
      this.getList();
    },
    /*
     *  双击进入详情
     */
    handleDalclick(row) {
      this.isDetails = true;
      this.allot_statistical_id = row.allot_statistical_id;
    },
    /*
     *  查看详情
     */
    handleDetails(row) {
      this.allot_statistical_id = row.allot_statistical_id;
      this.isDetails = true;
    },
    /*
     *  编辑
     */
    handleEdit(row) {
      this.allot_statistical_id = row.allot_statistical_id;
      this.isAdd = true;
    },
    /*
     *  删除
     */
    handleDel(row) {
      this.$confirm("此操作将删除该统计数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteStatistical({
          allot_statistical_id: row.allot_statistical_id,
        }).then((res) => {
          if (res.code === 1) {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.getList();
          }
        });
      });
    },
    /*
     *  分页操作
     */
    handleSizeChange(val) {
      this.cond.page_size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.cond.page = val;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.middle {
  /deep/ .el-form-item {
    margin-bottom: 0px;
  }
}
</style>