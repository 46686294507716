<template>
  <div>
    <div class="wrapper">
      <div class="content">
        <div class="card">
          <el-page-header @back="goBack" content="调拨统计对比详情">
          </el-page-header>
        </div>
        <div class="content">
          <div class="storeContent">
            <div class="storeBox">
              <div class="storeImg">
                <img :src="cond.hierarchy_logo || no_user" />
              </div>
              <div>
                <div class="storeName" style="color: #2791ff">
                  {{ cond.hierarchy_name }}
                </div>
                <div class="storeTime">
                  签收时间：{{ handleDate(cond.day_time) }}
                </div>
              </div>
            </div>
            <div class="compare">
              <img src="../../../../assets/images/goods/compare.png" />
            </div>
            <div class="storeBox">
              <div class="storeImg">
                <img :src="contrastCond.hierarchy_logo || no_user" />
              </div>
              <div>
                <div class="storeName" style="color: #2aad67">
                  {{ contrastCond.hierarchy_name }}
                </div>
                <div class="storeTime">
                  签收时间：{{ handleDate(contrastCond.day_time * 1000) }}
                </div>
              </div>
            </div>
            <div class="againButton">
              <el-button type="primary" @click="handleAgainContrast"
                >重新对比</el-button
              >
            </div>
          </div>
          <div class="condition">
            统计条件<span class="condition_box"
              >调拨类型：{{ allot_type === "10" ? "调出" : "调入" }}</span
            ><span class="condition_box"
              >统计类型：{{ time_typeInfo[time_type] }}统计</span
            >
          </div>
        </div>
      </div>
      <div
        class="content"
        style="
          margin-top: 10px;
          padding-bottom: 11px;
          border: 1px solid #e8e8e8;
        "
      >
        <div class="trabsTitle">货品统计</div>
        <div class="conditionData">
          <template v-for="(item, index) in goodsInfoList">
            <div
              class="condBox"
              style="margin-right: 10px; color: #2791ff"
              :key="index"
            >
              <div class="num">
                {{ parseFloat(parseFloat(item.value).toFixed(4)) }}
              </div>
              <div class="text">{{ item.text }}</div>
            </div>
          </template>
        </div>
        <div class="conditionData">
          <template v-for="(item, index) in goodsInfoOtherList">
            <div
              class="condBox"
              style="background: #f2faf6; margin-right: 10px; color: #2aad67"
              :key="index"
            >
              <div class="num">
                {{ parseFloat(parseFloat(item.value).toFixed(4)) }}
              </div>
              <div class="text">{{ item.text }}</div>
            </div>
          </template>
        </div>
        <div id="storeColumn" class="storeColumn"></div>
      </div>
      <div
        class="content"
        style="
          margin-top: 10px;
          padding-bottom: 11px;
          border: 1px solid #e8e8e8;
        "
      >
        <div class="trabsTitle">旧料统计</div>
        <div class="conditionData">
          <template v-for="(item, index) in goodsOldInfoList">
            <div
              class="condBox"
              style="margin-right: 10px; color: #2791ff"
              :key="index"
            >
              <div class="num">
                {{ parseFloat(parseFloat(item.value).toFixed(4)) }}
              </div>
              <div class="text">{{ item.text }}</div>
            </div>
          </template>
        </div>
        <div class="conditionData">
          <template v-for="(item, index) in goodsOldInfoOtherList">
            <div
              class="condBox"
              style="background: #f2faf6; margin-right: 10px; color: #2aad67"
              :key="index"
            >
              <div class="num">
                {{ parseFloat(parseFloat(item.value).toFixed(4)) }}
              </div>
              <div class="text">{{ item.text }}</div>
            </div>
          </template>
        </div>
        <div id="storeColumn" class="storeColumn"></div>
      </div>
      <div
        class="content"
        style="
          border: 1px solid #e8e8e8;
          padding-bottom: 20px;
          border-radius: 7px;
          margin-top: 10px;
        "
      >
        <div class="trabsTitle">货品维度统计</div>
        <div style="padding-left: 18px">
          <el-radio-group
            v-model="tabPosition"
            style="margin-bottom: 15px"
            @change="handleDimensionType"
          >
            <template v-for="(item, index) in dimension">
              <el-radio-button
                :label="String(item.attr_id)"
                :key="index"
                v-if="item.is_set === 1"
                >{{ item.attr_name }}</el-radio-button
              >
            </template>
          </el-radio-group>
        </div>
        <div class="tab">
          <el-tabs
            v-model="statisticalAttr_id"
            style="height: 200px; width: 90%"
            @tab-click="handleStatisticType"
          >
            <template v-for="(item, index) in statistical_header">
              <el-tab-pane
                :label="item.attr_name"
                :name="String(item.attr_id)"
                :key="index"
                v-if="item.is_set === 1"
              ></el-tab-pane>
            </template>
          </el-tabs>
        </div>
        <div class="containData">
          <div style="position: absolute; top: 10px; right: 10px">
            <el-form :model="form" style="width: 200px">
              <el-select
                placeholder="请选择统计类型"
                v-model="form.time_type"
                @change="handleDayTime(1)"
                style="width: 80%"
              >
                <el-option value="year" label="按月"></el-option>
                <el-option value="month" label="按天"></el-option>
                <el-option value="day" label="按小时"></el-option>
                <!-- <el-option value="40" label="自定义时间"></el-option> -->
              </el-select>
            </el-form>
          </div>
          <div style="display: flex; margin-top: 50px">
            <div id="container"></div>
            <div id="line" style="margin-left: 150px"></div>
          </div>
        </div>
        <div
          class="table"
          style="margin: 15px; border-top: 1px solid #e8e8e8"
          v-if="statisticalRowList.length"
        >
          <el-table
            :data="statisticalData"
            stripe
            max-height="500"
            :summary-method="getSummaries"
            show-summary
          >
            <template v-for="(item, index) in statisticalRowList">
              <el-table-column
                :key="index"
                align="center"
                :prop="item.header_id"
                :label="item.name"
              >
                <template slot-scope="scope">
                  <div
                    v-if="
                      scope.row[item.header_id] &&
                      scope.row[item.header_id].indexOf('/') != -1
                    "
                  >
                    <span style="color: rgb(39, 145, 255)">{{
                      scope.row[item.header_id].split("/")[0]
                    }}</span>
                    /
                    <span style="color: #25af67">{{
                      scope.row[item.header_id].split("/")[1]
                    }}</span>
                  </div>
                  <div v-else>{{ scope.row[item.header_id] }}</div>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
      </div>
      <div
        class="content"
        style="
          border: 1px solid #e8e8e8;
          padding-bottom: 20px;
          border-radius: 7px;
          margin-top: 10px;
        "
      >
        <div class="trabsTitle">旧料维度统计</div>
        <div style="padding-left: 18px">
          <el-radio-group
            v-model="tabPosition_old"
            style="margin-bottom: 15px"
            @change="handleDimensionType_old"
          >
            <template v-for="(item, index) in dimension">
              <el-radio-button
                :label="String(item.attr_id)"
                :key="index"
                v-if="item.is_set === 1"
                >{{ item.attr_name }}</el-radio-button
              >
            </template>
          </el-radio-group>
        </div>
        <div class="tab">
          <el-tabs
            v-model="statisticalAttr_id_old"
            style="height: 200px; width: 90%"
            @tab-click="handleStatisticType_old"
          >
            <template v-for="(item, index) in statistical_header_old">
              <el-tab-pane
                :label="item.attr_name"
                :name="String(item.attr_id)"
                :key="index"
                v-if="item.is_set === 1"
              ></el-tab-pane>
            </template>
          </el-tabs>
        </div>
        <div class="containData">
          <div style="position: absolute; top: 10px; right: 10px">
            <el-form :model="form" style="margin-left: 30px">
              <el-select
                placeholder="请选择统计类型"
                v-model="old_form.time_type"
                style="width: 80%"
                @change="handleDayTime(2)"
              >
                <el-option value="year" label="按月"></el-option>
                <el-option value="month" label="按天"></el-option>
                <el-option value="day" label="按小时"></el-option>
                <!-- <el-option value="40" label="自定义时间"></el-option> -->
              </el-select>
            </el-form>
          </div>
          <div style="display: flex; margin-top: 50px">
            <div id="container_old"></div>
            <div id="line_old" style="margin-left: 150px"></div>
          </div>
        </div>
        <div
          class="table"
          style="margin: 15px; border-top: 1px solid #e8e8e8"
          v-if="statisticalRowList_old.length"
        >
          <el-table
            :data="statisticalData_old"
            stripe
            max-height="500"
            :summary-method="getSummaries"
            show-summary
          >
            <template v-for="(item, index) in statisticalRowList_old">
              <el-table-column
                :key="index"
                align="center"
                :prop="item.header_id"
                :label="item.name"
              >
                <template slot-scope="scope">
                  <div
                    v-if="
                      scope.row[item.header_id] &&
                      scope.row[item.header_id].indexOf('/') != -1
                    "
                  >
                    <span style="color: rgb(39, 145, 255)">{{
                      scope.row[item.header_id].split("/")[0]
                    }}</span>
                    /
                    <span style="color: #25af67">{{
                      scope.row[item.header_id].split("/")[1]
                    }}</span>
                  </div>
                  <div v-else>
                    {{ scope.row[item.header_id] }}
                  </div>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
      </div>
      <el-dialog title="数据对比" :visible.sync="contrastVisiable" width="25%">
        <div>
          <el-form
            :model="contrastCond"
            style="margin: 0 auto"
            label-width="80px"
          >
            <el-form-item
              label="统计门店"
              style="margin-bottom: 20px !important"
             
            >
              <el-select
                v-model="contrastCond.hierarchy_id"
                placeholder="请选择统计门店"
              >
                <template v-for="(item, index) in storeList">
                  <el-option
                    :label="item.name"
                    :value="String(item.hierarchy_id)"
                    :key="index"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item label="签收时间" prop="day_time">
              <el-date-picker
                v-if="time_type === 'year'"
                v-model="contrastCond.day_time"
                type="year"
                placeholder="选择年"
                value-format="timestamp"
              >
              </el-date-picker>
              <el-date-picker
                v-if="time_type === 'month'"
                v-model="contrastCond.day_time"
                type="month"
                placeholder="选择月"
                value-format="timestamp"
              >
              </el-date-picker>
              <el-date-picker
                v-if="time_type === 'day'"
                v-model="contrastCond.day_time"
                type="date"
                placeholder="选择天"
                value-format="timestamp"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
          <div style="text-align: right; margin-top: 20px">
            <el-button @click="() => (contrastVisiable = false)"
              >取消</el-button
            >
            <el-button type="primary" @click="handleToContrast">确定</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { Radar, Line, Column } from "@antv/g2plot";
import {
  getTotalStatisticalData,
  getTotalStatisticalRadar,
  getTotalStatisticalTime,
  getStatistical,
  getTotalStatisticalList,
  getAttrValueList,
  downloadList,
  getMaterialListReq,
} from "@/api/statistics/transfer/index.js";
import no_user from "../../../../assets/images/no_user.png";
import { getListReq as getTypeListReq } from "@/api/goods/goodsConfig/classify";
import {
  getSupplierList,
  getWarehouseList,
} from "@/api/goods/goodsWarehousing/warehousing";
import Cookies from "js-cookie";
import storage from "good-storage";
import { getHierarchyAll } from "@/api/index";

export default {
  data() {
    return {
      userInfo: {},
      tabPosition: "",
      info: {},
      radarData: [],
      lineData: [],
      allot_type: null,
      time_type: null,
      dimension: [],
      list_header: [],
      statistical_header: [],
      statisticalData: [],
      no_user: no_user,
      attr_id: null,
      statisticalAttr_id: "",
      statisticalRowList: [],
      line: null,
      radarPlot: null,
      form: {},
      tabPosition_old: "",
      line_old: null,
      radarPlot_old: null,
      radarData_old: [],
      lineData_old: [],
      statisticalAttr_id_old: "",
      statisticalData_old: [],
      statistical_header_old: [],
      statisticalRowList_old: [],
      attr_id_old: null,
      goodsInfoList: [],
      goodsInfoOtherList: [],
      goodsOldInfoList: [],
      goodsOldInfoOtherList: [],
      time_typeInfo: {
        month: "按月",
        day: "按天",
        year: "按年",
      },
      contrastVisiable: false,
      storeList: [],
      form: {
        time_type: "",
      },
      old_form: {
        time_type: "",
      },
    
    };
  },

  created() {
    this.userInfo = storage.get("userInfo");
  },
  props: {
    allot_statistical_id: Number,
    contrastCond: Object,
    cond: Object,
  },
  mounted() {
    this.getMerchants();
    this.getStatistical();
  },
  methods: {
    /*
     *  重新对比
     */
    handleAgainContrast() {
      this.contrastCond.day_time = this.contrastCond.day_time * 1000;
      this.contrastVisiable = true;
    },
    /*
     *  获取门店数据
     */
    getMerchants() {
      getHierarchyAll({
        hierarchy_id: storage.get("userInfo").hierarchy_ids,
      }).then((res) => {
        if (res.code === 1) {
          if (this.userInfo.hierarchy_type_id === 30) {
            res.data.unshift({ hierarchy_id: 0, name: "全部门店" });
          }
          this.storeList = res.data;
        }
      });
    },
    /*
     *  时间转换
     */
    handleDate(date) {
      let time = "";
      if (this.time_type === "year") {
        time = new Date(date).toLocaleDateString().split("/")[0] + "年";
      } else if (this.time_type === "month") {
        time =
          new Date(date).toLocaleDateString().split("/")[0] +
          "-" +
          new Date(date).toLocaleDateString().split("/")[1];
      } else {
        time = new Date(date).toLocaleDateString().replace(/\//g, "-");
      }
      return time;
    },
    /*
     *  时间筛选
     */
    handleDayTime(type) {
      if (type === 1) {
        this.getTotalStatisticalTime();
      } else {
        this.getTotalStatisticalTime_old();
      }
    },
    /*
     *  获取调拨统计默认或设置项
     */
    getStatistical() {
      getStatistical({
        allot_statistical_id: this.allot_statistical_id,
        goods_type: 10,
        diff_hierarchy_id: this.contrastCond.hierarchy_id,
        diff_day_time: this.contrastCond.day_time,
        hierarchy_id: this.cond.hierarchy_id,
        day_time: this.cond.day_time / 1000,
      }).then((res) => {
        if (res.code === 1) {
          this.info = res.data.statistical;
          this.allot_type = res.data.statistical.conditions.allot_type;
          this.time_type = res.data.statistical.conditions.time_type;
          this.dimension = res.data.dimension;
          this.list_header = res.data.list_header;
          this.statistical_header = res.data.statistical_header;
          this.attr_id = res.data.dimension.filter(
            (item) => item.is_set === 1
          )[0].attr_id;
          this.tabPosition = String(
            res.data.dimension.filter((item) => item.is_set === 1)[0].attr_id
          );
          this.statisticalAttr_id = String(
            res.data.statistical_header.filter((item) => item.is_set === 1)[0]
              .attr_id
          );
          this.getTotalStatisticalData();
          this.dimension_old = res.data.dimension;
          this.statistical_header_old = res.data.statistical_header;
          this.attr_id_old = res.data.dimension.filter(
            (item) => item.is_set === 1
          )[0].attr_id;
          this.tabPosition_old = String(
            res.data.dimension.filter((item) => item.is_set === 1)[0].attr_id
          );
          this.statisticalAttr_id_old = String(
            res.data.statistical_header.filter((item) => item.is_set === 1)[0]
              .attr_id
          );
          this.form = {
            time_type: res.data.statistical.conditions.time_type,
          };
          this.old_form = {
            time_type: res.data.statistical.conditions.time_type,
          };
          this.getTotalStatisticalData_old();
        }
      });
    },
    /*
     * 调拨统计-获取统计维度数据
     */
    getTotalStatisticalData() {
      this.goodsInfoList = [];
      this.goodsInfoOtherList = [];
      // this.statisticalData = [];
      getTotalStatisticalData({
        allot_statistical_id: this.allot_statistical_id,
        goods_type: 10,
        dimension: this.attr_id,
        diff_hierarchy_id: this.contrastCond.hierarchy_id,
        diff_day_time: this.contrastCond.day_time,
        hierarchy_id: this.cond.hierarchy_id,
        day_time: this.cond.day_time / 1000,
      }).then((res) => {
        if (res.code === 1) {
          this.statisticalData = res.data.list ? res.data.list : [];
          this.statisticalRowList = res.data.header;

          res.data.header.forEach((item, index) => {
            if (index !== 0) {
              const info = {
                field: item.header_id,
                text: item.name,
                value: 0,
              };
              const info_other = {
                field: item.header_id,
                text: item.name,
                value: 0,
              };
              if (res.data.list) {
                res.data.list.forEach((items) => {
                  Object.keys(items).forEach((i) => {
                    if (items[item.header_id].indexOf("/") != -1) {
                      if (i === item.header_id) {
                        info.value += Number(
                          items[item.header_id].split("/")[0]
                        );
                        info_other.value += Number(
                          items[item.header_id].split("/")[1]
                        );
                      }
                    }
                  });
                });
              }

              this.goodsInfoList.push({ ...info });
              this.goodsInfoOtherList.push({ ...info_other });
            }
          });

          this.getTotalStatisticalTime();
          this.getTotalStatisticalRadar();
        }
      });
    },
    getTotalStatisticalData_old() {
      this.goodsOldInfoList = [];
      this.goodsOldInfoOtherList = [];
      getTotalStatisticalData({
        allot_statistical_id: this.allot_statistical_id,
        goods_type: 20,
        dimension: this.attr_id_old,
        diff_hierarchy_id: this.contrastCond.hierarchy_id,
        diff_day_time: this.contrastCond.day_time,
        hierarchy_id: this.cond.hierarchy_id,
        day_time: this.cond.day_time / 1000,
      }).then((res) => {
        if (res.code === 1) {
          this.statisticalData_old = res.data.list ? res.data.list : [];
          this.statisticalRowList_old = res.data.header;

          res.data.header.forEach((item, index) => {
            if (index !== 0) {
              const info = {
                field: item.header_id,
                text: item.name,
                value: 0,
              };
              const info_other = {
                field: item.header_id,
                text: item.name,
                value: 0,
              };
              if (res.data.list) {
                res.data.list.forEach((items) => {
                  Object.keys(items).forEach((i) => {
                    if (items[item.header_id].indexOf("/") != -1) {
                      if (i === item.header_id) {
                        info.value += Number(
                          items[item.header_id].split("/")[0]
                        );
                        info_other.value += Number(
                          items[item.header_id].split("/")[1]
                        );
                      }
                    }
                  });
                });
              }

              this.goodsOldInfoList.push({ ...info });
              this.goodsOldInfoOtherList.push({ ...info_other });
            }
          });
          this.getTotalStatisticalTime_old();
          this.getTotalStatisticalRadar_old();
        }
      });
    },
    /*
     *  时间轴
     */
    getTotalStatisticalTime() {
      const data = {
        allot_statistical_id: this.allot_statistical_id,
        goods_type: 10,
        dimension: this.attr_id,
        statistical_type: this.statisticalAttr_id,
        diff_hierarchy_id: this.contrastCond.hierarchy_id,
        diff_day_time: this.contrastCond.day_time,
        hierarchy_id: this.cond.hierarchy_id,
        day_time: this.cond.day_time / 1000,
      };

      if (this.form.time_type) {
        data.time_type = this.form.time_type;
      }

      getTotalStatisticalTime(data).then((res) => {
        if (res.code === 1) {
          if (res.data.length !== 0) {
            if (this.lineData.length !== 0) {
              this.line.destroy();
            }
            this.lineData = res.data.result;
            this.handleLine();
          } else if (res.data.length === 0) {
            this.line.destroy();
          }
        }
      });
    },
    /*
     *  时间轴-旧料
     */
    getTotalStatisticalTime_old() {
      const data = {
        allot_statistical_id: this.allot_statistical_id,
        goods_type: 20,
        dimension: this.attr_id,
        statistical_type: this.statisticalAttr_id,
        diff_hierarchy_id: this.contrastCond.hierarchy_id,
        diff_day_time: this.contrastCond.day_time,
        hierarchy_id: this.cond.hierarchy_id,
        day_time: this.cond.day_time / 1000,
      };

      if (this.old_form.time_type) {
        data.time_type = this.old_form.time_type;
      }

      getTotalStatisticalTime(data).then((res) => {
        if (res.code === 1) {
          if (res.data.length !== 0) {
            if (this.lineData_old.length !== 0) {
              this.line_old.destroy();
            }
            this.lineData_old = res.data.result;
            this.handleLine_old();
          } else if (res.data.length === 0) {
            this.lineData_old = [];
            this.line_old.destroy();
          }
        }
      });
    },
    /*
     *  获取雷达图数据
     */
    getTotalStatisticalRadar() {
      const data = {
        allot_statistical_id: this.allot_statistical_id,
        goods_type: 10,
        dimension: this.attr_id,
        statistical_type: this.statisticalAttr_id,
        diff_hierarchy_id: this.contrastCond.hierarchy_id,
        diff_day_time: this.contrastCond.day_time,
        hierarchy_id: this.cond.hierarchy_id,
        day_time: this.cond.day_time / 1000,
      };
      // if (this.form.day_time) {
      //   data.day_time = this.form.day_time / 1000;
      // }
      getTotalStatisticalRadar(data).then((res) => {
        if (res.code === 1) {
          if (res.data.length !== 0) {
            if (this.radarPlot) {
              this.radarPlot.destroy();
            }
            this.radarData = res.data;
            this.handleRadar();
          } else {
            this.radarData = [];
            this.radarPlot.destroy();
          }
        }
      });
    },
    /*
     *  获取雷达图数据-旧料
     */
    getTotalStatisticalRadar_old() {
      const data = {
        allot_statistical_id: this.allot_statistical_id,
        goods_type: 10,
        dimension: this.attr_id,
        statistical_type: this.statisticalAttr_id,
        diff_hierarchy_id: this.contrastCond.hierarchy_id,
        diff_day_time: this.contrastCond.day_time,
        hierarchy_id: this.cond.hierarchy_id,
        day_time: this.cond.day_time / 1000,
      };
      // if (this.form.day_time) {
      //   data.day_time = this.form.day_time / 1000;
      // }
      getTotalStatisticalRadar(data).then((res) => {
        if (res.code === 1) {
          if (res.data.length !== 0) {
            if (this.radarPlot_old) {
              this.radarPlot_old.destroy();
            }
            this.radarData_old = res.data;
            this.handleRadar_old();
          } else {
            this.radarData_old = [];
            this.radarPlot_old.destroy();
          }
        }
      });
    },
    handleDimensionType(tab) {
      this.attr_id = tab;
      this.getTotalStatisticalData();
    },
    handleStatisticType(tab, event) {
      this.getTotalStatisticalTime();
      this.getTotalStatisticalRadar();
    },
    handleDimensionType_old(tab) {
      this.attr_id_old = tab;
      this.getTotalStatisticalData_old();
    },
    handleStatisticType_old(tab, event) {
      this.getTotalStatisticalTime_old();
      this.getTotalStatisticalRadar_old();
    },

    goBack() {
      this.$emit("hide");
    },
    handleStoreColumn() {
      const stackedColumnPlot = new Column("storeColumn", {
        data: this.info,
        isGroup: true,
        xField: "月份",
        yField: "月均降雨量",
        seriesField: "name",
        width: 800,
        height: 440,
        /** 设置颜色 */
        //color: ['#1ca9e6', '#f88c24'],
        /** 设置间距 */
        // marginRatio: 0.1,
        label: {
          // 可手动配置 label 数据标签位置
          position: "middle", // 'top', 'middle', 'bottom'
          // 可配置附加的布局方法
          layout: [
            // 柱形图数据标签位置自动调整
            { type: "interval-adjust-position" },
            // 数据标签防遮挡
            { type: "interval-hide-overlap" },
            // 数据标签文颜色自动调整
            { type: "adjust-color" },
          ],
        },
      });

      stackedColumnPlot.render();
    },
    handleRadar() {
      this.radarPlot = new Radar("container", {
        data: this.radarData,
        xField: "item",
        yField: "score",
        seriesField: "user",
        width: 393,
        radius: 0.65,
        xAxis: {
          line: null,
          tickLine: null,
          grid: {
            line: {
              style: {
                lineDash: null,
              },
            },
          },
        },
        yAxis: {
          line: null,
          tickLine: null,
          grid: {
            line: {
              type: "line",
              style: {
                lineDash: null,
              },
            },
          },
        },
        // 开启面积
        area: {},
      });
      this.radarPlot.render();
    },
    handleLine() {
      this.line = new Line("line", {
        data: this.lineData,
        xField: "time",
        yField: "data",
        seriesField: "name",
        yAxis: {
          label: {
            formatter: (v) => `${(v / 10e8).toFixed(1)} B`,
          },
        },
        legend: {
          position: "top",
        },
        smooth: true,
        animation: {
          appear: {
            animation: "path-in",
            duration: 5000,
          },
        },
        width: 1100,
        yAxis: {
          label: {
            // 数值格式化为千分位
            formatter: (v) =>
              `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
          },
        },
      });

      this.line.render();
    },
    handleRadar_old() {
      this.radarPlot_old = new Radar("container_old", {
        data: this.radarData_old,
        xField: "item",
        yField: "score",
        seriesField: "user",
        width: 393,
        radius: 0.65,
        height: 350,
        xAxis: {
          line: null,
          tickLine: null,
          grid: {
            line: {
              style: {
                lineDash: null,
              },
            },
          },
        },
        yAxis: {
          line: null,
          tickLine: null,
          grid: {
            line: {
              type: "line",
              style: {
                lineDash: null,
              },
            },
          },
        },
        // 开启面积
        area: {},
      });
      this.radarPlot_old.render();
    },
    handleLine_old() {
      this.line_old = new Line("line_old", {
        data: this.lineData_old,
        xField: "time",
        yField: "data",
        seriesField: "name",
        yAxis: {
          label: {
            formatter: (v) => `${(v / 10e8).toFixed(1)} B`,
          },
        },
        legend: {
          position: "top",
        },
        smooth: true,
        animation: {
          appear: {
            animation: "path-in",
            duration: 5000,
          },
        },
        width: 1100,
        yAxis: {
          label: {
            // 数值格式化为千分位
            formatter: (v) =>
              `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
          },
        },
      });

      this.line_old.render();
    },
    /*
     *   合计
     */
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values =
          data.map((item) => Number(item[column.property].split("/")[0])) || [];
        const valuesOther =
          data.map((item) => Number(item[column.property].split("/")[1])) || [];
        if (!values.every((value) => isNaN(value))) {
          sums[index] =
            values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return parseFloat(parseFloat(prev + curr).toFixed(4));
              } else {
                return prev;
              }
            }, 0) +
            " / " +
            valuesOther.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return parseFloat(parseFloat(prev + curr).toFixed(4));
              } else {
                return prev;
              }
            }, 0);

          sums[index] += "";
        } else {
          sums[index] = "N/A";
        }
      });
      return sums;
    },
    handleToContrast() {
      this.contrastVisiable = false;
      this.storeList.forEach((item) => {
        if (item.hierarchy_id === Number(this.contrastCond.hierarchy_id)) {
          this.contrastCond.hierarchy_name = item.name;
          this.contrastCond.hierarchy_logo = item.logo;
        }
      });
      this.contrastCond.day_time = this.contrastCond.day_time / 1000;
      this.getStatistical();
    },
  },
};
</script>

<style lang="less" scoped>
.storeContent {
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  display: flex;
  padding: 22px 0 22px 42px;
  height: 44px;
  .compare {
    margin: auto 40px;
    width: 30px;
    height: 24px;
    img {
      width: 30px;
      height: 24px;
    }
  }
  .againButton {
    margin-left: 60px;
    height: 26px;
  }
  .storeBox {
    display: flex;

    .storeImg {
      margin-right: 10px;
      width: 44px;
      height: 44px;
      img {
        margin-right: 10px;
        width: 44px;
        height: 44px;
        border-radius: 50%;
      }
    }
    .storeName {
      font-size: 18px;
    }
    .storeTime {
      font-size: 12px;
      color: #909399;
    }
  }
}
.condition {
  border-radius: 0px 0px 6px 6px;
  border: 1px solid #e8e8e8;
  line-height: 58px;
  padding-left: 21px;
  .condition_box {
    margin-left: 15px;
    padding: 8px 10px;
    border: 1px solid #c4cfdf;
    border-radius: 7px;
    font-size: 12px;
    color: #909399;
  }
}
.conditionData {
  margin-left: 15px;
  display: flex;
  padding: 11px 13px;
  max-width: 90%;
  overflow: auto;
  .condBox {
    white-space: nowrap;
    padding: 15px 40px 15px 40px;
    opacity: 1;
    background: #f8fbfe;
    border-radius: 4px;
    text-align: center;

    .num {
      font-size: 22px;
    }
    .text {
      color: #909399;
    }
  }
}
.storeColumn {
  margin: 10px 20px 0 20px;
}
.trabsTitle {
  padding-left: 18px;
  height: 50px;
  line-height: 50px;
  font-weight: 600;
  font-size: 15px;
}
.trabsTitle {
  padding-left: 18px;
  height: 50px;
  line-height: 50px;
  font-weight: 600;
  font-size: 15px;
}
.tab {
  display: flex;
  padding: 0 18px;
  margin: 0 18px;
  height: 58px;
  background: linear-gradient(0deg, #f3f9ff 1%, #ffffff 100%);
  border: 1px solid #e8e8e8;
  border-radius: 6px 6px 0px 0px;
  line-height: 58px;

  .tabBox {
    position: relative;
    padding: 0 30px;

    .label {
      position: absolute;
      bottom: 0;
      left: 41%;
      width: 18px;
      height: 4px;
      opacity: 1;
      background: #2791ff;
      border-radius: 2px;
    }
  }
}
.containData {
  position: relative;
  padding: 15px 0 15px 100px;
  margin: 0 18px;
  border: 1px solid #e8e8e8;
  border-top: none;
}
</style>